export interface AmplitudeEvent {
  eventName: EVENTS;
  params?: Record<string, string | number | boolean>;
}

export enum EVENTS {
  TOGGLE_LID = '[ROBOT]_TOGGLE_LID',
  OPEN_LID_SUCCESS = '[ROBOT]_LID_OPEN_SUCCESS',
  OPEN_LID_FAILURE = '[ROBOT]_LID_OPEN_FAILURE',
  OPEN_LID_RETRY = '[ROBOT]_OPEN_LID_RETRY',
  CLOSE_LID_RETRY = '[ROBOT]_CLOSE_LID_RETRY',
  ROBOT_LOAD_FAILURE = '[ROBOT]_LOAD_FAILURE',
  ROBOT_LOAD_RETRY = '[ROBOT]_LOAD_RETRY',
  ROBOT_LOAD_SUCCESS = '[ROBOT]_LOAD_SUCCESS',
  SCAN_ANOTHER = '[ROBOT]_SCAN_ANOTHER',
  RETURN_TO_SCAN = '[ROBOT]_RETURN_TO_SCAN',
  ROBOT_UNAVAILABLE = '[ROBOT]_ROBOT_UNAVAILABLE',
  ORDER_LOAD_STARTED = '[ORDER]_LOAD_STARTED',
  ORDER_LOAD_COMPLETED = '[ORDER]_LOAD_COMPLETED',
  ORDER_LOAD_SUCCESS = '[ORDER]_LOAD_SUCCESS',
  ORDER_LOAD_FAILURE = '[ORDER]_LOAD_FAILURE',
  ORDER_SEARCH_USE = '[ORDER]SEARCH_USE',
  ORDER_NO_ORDERS = '[ORDER]_NO_ORDERS',
  AUTH_LOGOUT = '[AUTH]_LOGOUT',
  AUTH_AUTO_LOGOUT = '[AUTH]_AUTO_LOGOUT',
  ORDER_SELECTED = '[ORDER]_SELECTED',
  ORDER_UPDATED = '[ORDER]_UPDATED',
  ORDER_LOAD_ANOTHER = '[ORDER]_LOAD_ANOTHER',
  ORDER_GET_ERROR = '[ORDER]_GET_ORDER_ERROR',
  SCANNER_OPEN = '[SCANNER]_OPEN',
  SCANNER_SCAN_ERROR = '[SCANNER]_SCAN_ERROR',
  NETWORK_API_ERROR = '[NETWORK]_API_ERROR',
  DELIVERIES_NO_ORDERS = '[DELIVERIES]_NO_ORDERS',
  ORDER_VIEW_ORDERS = '[ORDER]_VIEW_ORDERS',
  OPEN_LID_PROFILING = '[NETWORK]_OPEN_LID_PROFILING',
  GET_ROBOT_ETA_ERROR = '[ROBOT]_GET_ETA_ERROR',
  GET_ROBOT_ETA_SUCCESS = '[ROBOT]_GET_ETA_SUCCESS',
  GET_LOADABLE_ROBOTS_ERROR = '[ROBOT]_GET_LOADABLE_ROBOTS_ERROR',
  CLICK_OPEN_DIFFERENT_ROBOT = '[USER]_CLICK_OPEN_DIFFERENT_ROBOT',
  AUTO_OPENING_NEXT_ROBOT = '[ROBOT]_AUTO_OPENING_NEXT_ROBOT',
}

export const ERROR_EVENTS: EVENTS[] = [
  EVENTS.OPEN_LID_FAILURE,
  EVENTS.ROBOT_LOAD_FAILURE,
  EVENTS.ORDER_LOAD_FAILURE,
  EVENTS.SCANNER_SCAN_ERROR,
  EVENTS.NETWORK_API_ERROR,
  EVENTS.GET_LOADABLE_ROBOTS_ERROR,
];
